import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import ClipLoader from "react-spinners/ClipLoader";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";

import requests from "../../../services/requests";
import { customLabels, customLegend, customMarkers } from "../shared/layout";
import { colors } from "../../../assets/colors";
import { createSeries } from "../shared/createElements";

am4core.useTheme(am4themesAnimated);

function GraphProcessByStage({ filterInformation, searchString, searchPeriod }) {
    const x = useRef(null);

    const [load, setLoad] = useState(true);

    const getData = useCallback(async chart => {
        const res = await requests.dashboardProcessByStage({
            search: searchString,
            filters: filterInformation,
            period: searchPeriod,
        });

        let aux = {};

        for (let x = 0; x < res?.length; x += 1) {
            aux = { ...aux, category: "Etapas", [res[x].stage]: res[x].qtt };
        }
        chart.data = [aux];
        setLoad(false);
    });

    useLayoutEffect(() => {
        const chart = am4core.create("graphStage", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;
        // Add data

        chart.colors.list = [
            am4core.color("#412B75"),
            am4core.color("#634A98"),
            am4core.color("#866ABB"),
            am4core.color("#AA8BE0"),
            am4core.color("#D0B0FD"),
            am4core.color("#FAD8FF"),
            am4core.color("#CECECE"),
        ];

        // Export
        // chart.exporting.menu = new am4core.ExportMenu();

        // Create axes
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.opposite = true;

        const yLabels = yAxis.renderer.labels;
        customLabels(yLabels);

        const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
        xAxis.max = 100;
        xAxis.strictMinMax = true;
        xAxis.calculateTotals = true;
        xAxis.renderer.minHeight = 40;
        xAxis.renderer.disabled = true;
        xAxis.renderer.grid.template.disabled = true;

        createSeries(chart, "Pré embarque");
        createSeries(chart, "Trânsito");
        createSeries(chart, "Desembaraço");
        createSeries(chart, "Faturamento");
        createSeries(chart, "Transporte");
        createSeries(chart, "Entrega");
        createSeries(chart, "Sem etapa");

        const legend = customLegend(chart);
        legend.maxHeight = undefined;

        customMarkers(legend);

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}

            <div id="graphStage" style={{ width: "100%", marginBottom: 20, height: "300px" }} />
        </>
    );
}

export default GraphProcessByStage;
