import React from "react";
import GraphProcessByChannel from "./../../../../components/Graphs/GraphProcessByChannel";
import GraphProcessByStage from "./../../../../components/Graphs/GraphProcessByStage";
import Content from "./styles";

const Metric = ({ filterInformation, searchString, searchPeriod }) => {
    return (
        <Content>
            <article>
                <div className="horizontalBarsGraph">
                    <h6>Processos por etapa</h6>
                    <GraphProcessByStage
                        filterInformation={filterInformation}
                        searchString={searchString}
                        searchPeriod={searchPeriod}
                    />

                    <h6>Parametrização - Canal</h6>
                    <GraphProcessByChannel
                        filterInformation={filterInformation}
                        searchString={searchString}
                        searchPeriod={searchPeriod}
                    />
                </div>
                {/*<div className="graph">
          <h6>Porto x Embarques x Containers</h6>
          <GraphPortVersusBoardingVersusContainers
            filterInformation={filterInformation}
            searchString={searchString}
            searchPeriod={searchPeriod}
          />
          <Link to={`/analytics`}>
            <p className="text-roxo_oficial cursor-pointer">ver todas</p>
          </Link>
        </div>*/}
            </article>
        </Content>
    );
};
export default Metric;
