import React from "react";
import styled from "styled-components";

const Section = styled.section`
    display: flex;
    flex-direction: column;

    article {
        display: flex;
        justify-content: space-between;
        align-self: stretch;
        height: 100%;
        flex-wrap: wrap;

        .horizontalBarsGraph {
            flex-basis: 600px;
            flex-grow: 2;
            align-self: stretch;
        }
        .graph {
            flex-basis: 300px;
            flex-grow: 1;
            min-height: 440px;
        }
    }

    a {
        text-align: right;
        text-decoration: underline;
    }
`;
export default function Content({ children }) {
    return <Section>{children}</Section>;
}
