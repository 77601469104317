import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import ClipLoader from "react-spinners/ClipLoader";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";

import requests from "../../../services/requests";
import { customLabels, customLegend, customMarkers } from "../shared/layout";
import { createSeries } from "../shared/createElements";

am4core.useTheme(am4themesAnimated);

function GraphProcessByChannel({ filterInformation, searchString, searchPeriod }) {
    const x = useRef(null);

    const [load, setLoad] = useState(true);

    const getData = useCallback(async chart => {
        const res = await requests.dashboardProcessByChannel({
            search: searchString,
            filters: filterInformation,
            period: searchPeriod,
        });

        let aux = {};
        for (let x = 0; x < res?.length; x += 1) {
            aux = { ...aux, category: "Canal", [res[x].channel]: res[x].qtt };
        }

        chart.data = [aux];

        setLoad(false);
    });

    useLayoutEffect(() => {
        const chart = am4core.create("graphChannel", am4charts.XYChart);
        chart.language.locale = am4lang_pt_BR;

        chart.colors.list = [
            am4core.color("#EC5D66"),
            am4core.color("#F3D783"),
            am4core.color("#70D499"),
            am4core.color("#696969"),
            am4core.color("#e0e0e0"),
        ];

        // // Export
        // chart.exporting.menu = new am4core.ExportMenu();

        // Create axes
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.opposite = true;

        const yLabels = yAxis.renderer.labels;
        customLabels(yLabels);

        const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.min = 0;
        xAxis.max = 100;
        xAxis.strictMinMax = true;
        xAxis.calculateTotals = true;
        xAxis.renderer.minHeight = 40;
        xAxis.renderer.disabled = true;
        xAxis.renderer.grid.template.disabled = true;

        const xLabels = xAxis.renderer.labels;
        customLabels(xLabels);

        createSeries(chart, "Vermelho");
        createSeries(chart, "Amarelo");
        createSeries(chart, "Verde");
        createSeries(chart, "Cinza");
        createSeries(chart, "Sem canal");

        const legend = customLegend(chart);
        legend.maxHeight = undefined;
        customMarkers(legend);

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchString, filterInformation, searchPeriod]);

    return (
        <>
            {load && <ClipLoader size={20} loading={load} />}

            <div id="graphChannel" style={{ width: "100%", height: "250px" }} />
        </>
    );
}

export default GraphProcessByChannel;
